export class EmpRole {
  roleId: number;
  roleName: string;
  roleNameArabic: string;
  roleType: number;
  responsePercent: number;
  score: number;

  empCount = 0;
  leaderCount = 0;
  totalCount = 0;

  empCountPercent = "0%";
  leadercountPercent = "0%";

  static getEmpRoles(filterData): EmpRole[] {
    var empRoles: EmpRole[] = [];
    filterData.forEach((roleData) => {
      var empRole = new EmpRole();
      empRole.roleId = roleData.roleId;
      empRole.roleType = roleData.roleType;
      empRole.roleName = roleData.roleName;
      empRole.roleNameArabic = roleData.roleNameArabic;

      empRole.responsePercent = roleData.responsePercent ?? 0;
      empRole.score = roleData.score ?? 0;

      empRoles.push(empRole);
    });
    return empRoles;
  }
}
