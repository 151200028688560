import { Component , OnInit} from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { EventService } from './core/services/event.service';
import { LanguageService } from './core/services/language.service';
import { DefaultComponent } from './pages/dashboards/default/default.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  session;
  menuToDisplay = ['/account/login','/charts/dashboard','/dashboard', '/appointments/list', '/department-hierarchy', '/org-hierarchy','/changePassword'];

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    
    const initialUrl = this.router.url;
    this.handleUrlValidation(initialUrl);

    // Subscribe to route changes
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.session = JSON.parse(localStorage.getItem("SessionDetails"));
        const targetUrl = event.url;
        this.handleUrlValidation(targetUrl);
      }
    });
    }

    handleUrlValidation(url: string) {
      let isAllowedUrl = true;
      if (this.session?.userRole === 5) { isAllowedUrl = this.menuToDisplay.includes(url); }
      if (!isAllowedUrl) {
        Swal.fire({
          title: this.translate.instant("Error"),
          text: this.translate.instant("URL_not_allowed"),
          icon: "error",
          confirmButtonText: this.translate.instant("Ok")
        });
        this.router.navigateByUrl('/dashboard');
      }
    }

  
  isLogin: boolean;  
  openPopup: boolean;
  currentLang: string;
  title = 'ICMS Survey';

  constructor(
    public translateService: LanguageService, 
    public translate: TranslateService, 
    private eventService: EventService,
    public languageService: LanguageService,
    private router: Router,
  ){
    // this.translateService.setLanguage('ar');
    // console.log(this.translateService);
    // let htmlTag = document.getElementsByTagName("html")[0] as HTMLElement;
    // var lang = localStorage.getItem('currentLang');
    // htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
  }

  public changeLanguageAll(lang: string) {
    //alert(lang);
    //this.translateService.setLanguage(lang);
  }

  
}
